<template>
	<div class="relative w-full">
		<!-- - Toggler - -->
		<button
			id="selectButton"
			:aria-expanded="this.isOpen ? 'true' : 'false'"
			@click="toggle"
			class="w-full py-2 px-4"
		>
			<span class="text-sm font-semibold">
				{{ buttonText }}
			</span>
		</button>

		<!-- start of dropdown -->
		<div 
			class="
				absolute z-10 bg-white w-full transition-all 
				duration-300 mt-2 shadow-lg rounded-br-xl rounded-bl-xl
			"
			v-if="isOpen"
		>
			<ul class="w-full max-h-96 overflow-y-auto" role="listbox">
				<!-- - Loop over times - -->
				<div
					v-for="option in options.filter((option) => option.eventAks.length > 0)"
					:key="option.performanceDatetime"
					class="px-4 py-2"
				>
					<p class="rounded border-2 border-gray-600 p-2">
						{{ $moment(option.performanceDatetime).format("h:mm a") }}
					</p>

					<!-- - Loop over eventAks - -->
					<div
						v-for="eventAk in option.eventAks"
						:key="`${option.performanceDatetime}-${eventAk}`"
					>
						<button
							class="flex items-center justify-between w-full mt-2"
							@click="toggleOptionSelected(option.performanceDatetime, eventAk)"
						>
							<span> 
								{{ 
									events.find(
										(event) => (event.eventAk == eventAk)
									).eventName
								}}
							</span>

							<!-- - Checkmark Wrapper - -->
							<div
								class="w-8 h-8 bg-white text-white border-gray-600 border-2"
								:class="{
									'text-gray-600': 
										findIndexOptionSelected(option.performanceDatetime, eventAk) != -1
								}"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fill-rule="evenodd"
										d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
										clip-rule="evenodd"
									></path>
								</svg>
							</div>
						</button>
					</div>
				</div>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		// props
		props: {
			options: {
				type: Array
			},
			optionsSelected: {
				type: Array
				/**
				 * 	Example Object that would be found in the array:
				 * 	
				 * 	{
				 * 		"performanceDatetime": "2021-08-20T14:00:00.000Z",
				 * 		"eventAk": "TWB.ENV1"
				 * 	}
				 */
			},
			events: {
				type: Array
			},
			buttonText: {
				type: String,
				default: "Select..."
			},
			isOpen: {
				type: Boolean,
				default: false
			}
		},

		// methods
		methods: {
			toggle() {
				// Only open if we have options
				if(!this.options)
					return;

				// Tell the parent that we have toggled
				this.$emit("toggled", this.isOpen = !this.isOpen);
			},
			findIndexOptionSelected(performanceDatetime, eventAk) {
				return this.optionsSelected.findIndex(
					(optionSelected) => (
						optionSelected.performanceDatetime == performanceDatetime &&
						optionSelected.eventAk == eventAk
					)
				);
			},
			toggleOptionSelected(performanceDatetime, eventAk) {
				const optionSelectedIndex = this.findIndexOptionSelected(performanceDatetime, eventAk)
				if(optionSelectedIndex != -1) {
					this.optionsSelected.splice(optionSelectedIndex, 1);
				}
				else {
					this.optionsSelected.push(
						{
							"performanceDatetime": performanceDatetime,
							"eventAk": eventAk
						}
					)
				}
			}
		}
	}
</script>