<template>
	<div :id="$attrs.id" class="relative w-full inline-block text-left">
		<div>
			<button
				type="button"
				class="inline-flex justify-center w-full rounded-full border border-gray-300 shadow-sm px-4 py-3 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
				:id="`${$attrs.id}-button`"
				:aria-expanded="isOpen"
				aria-haspopup="true"
				@click="toggle"
			>
				<slot name="dropdown-menu-text">
					Options
				</slot>
				<IconChevronDown
					class="-mr-1 ml-2 h-5 w-5 transform transition-all ease-in-out duration-300"
					:class="{
						'rotate-180': isOpen
					}"
				/>
			</button>
		</div>

		<div
			class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none overflow-hidden"
			role="menu"
			aria-orientation="vertical"
			:aria-labelledby="`${$attrs.id}-button`"
			tabindex="-1"
			v-show="isOpen"
		>
			<slot name="dropdown-menu-options"></slot>
		</div>
	</div>
</template>

<script>
	import IconChevronDown from "@/components/IconChevronDown.vue";
	export default {
		data() {
			return {
				isOpen: false
			};
		},
		methods: {
			toggle() {
				this.isOpen = !this.isOpen;
			}
		},
		components: {
			IconChevronDown
		}
	};
</script>
