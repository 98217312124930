<template>
	<div>
		<!-- - Navigation Menu - -->
		<WaveLoader v-if="fetchingSurfersWaiversLoading" />
		<Nav :NavText="navText" />

		<!-- - Sub-navigation Menu - -->
		<div class="w-full bg-gray-600">
			<div class="container mx-auto py-2 lg:p-8 lg:py-3">
				<div
					class="w-full flex flex-wrap items-center justify-center p-2"
				>
					<!-- - SurfAkDropDown - -->
					<div class="w-4/12 lg:w-5/12 px-1">
						<div
							class="flex justify-start border bg-white mt-2 lg:mt-0"
							:class="{
								'rounded-tl-xl rounded-tr-xl ': surfAkDropDownIsOpen,
								'rounded-xl': !surfAkDropDownIsOpen
							}"
						>
							<SurfAkDropDown
								@toggled="(isOpen) => (this.surfAkDropDownIsOpen = isOpen)"
								:buttonText="surfAkDropDownButtonText"
								:events="surfAkDropDownEvents"
								:options="surfAkDropDownOptions"
								:optionsSelected.sync="surfAkDropDownOptionsSelected"
								:isOpen.sync="surfAkDropDownIsOpen"
							/>
						</div>
					</div>

					<!-- - Date Searching - -->
					<div class="w-4/12 lg:w-4/12 px-1">
						<div
							class="flex justify-start border bg-white rounded-xl overflow-hidden mt-2 lg:mt-0"
						>
							<div class="text-center flex-1">
								<input
									type="date"
									class="w-full px-4 py-2 text-gray-700 placeholder-gray-900 placeholder-gray-900::placeholder font-semibold text-sm"
									v-model="dateSearch"
								/>
							</div>
							<div
								class="flex items-center justify-center text-center"
							>
								<button
									class="px-4 border-l text-gray-500 hover:text-gray-900"
									v-on:click="dateSearchAction()"
								>
									<svg
										aria-hidden="true"
										focusable="false"
										data-icon="history"
										role="img"
										class="h-4 fill-current"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 512 512"
									>
										<path
											fill="currentColor"
											d="M504 255.532c.252 136.64-111.182 248.372-247.822 248.468-64.014.045-122.373-24.163-166.394-63.942-5.097-4.606-5.3-12.543-.443-17.4l16.96-16.96c4.529-4.529 11.776-4.659
												16.555-.395C158.208 436.843 204.848 456 256 456c110.549 0 200-89.468 200-200 0-110.549-89.468-200-200-200-55.52 0-105.708
												22.574-141.923 59.043l49.091 48.413c7.641 7.535 2.305 20.544-8.426 20.544H26.412c-6.627 0-12-5.373-12-12V45.443c0-10.651
												12.843-16.023 20.426-8.544l45.097 44.474C124.866 36.067 187.15 8 256 8c136.811 0 247.747 110.781 248 247.532zm-167.058
												90.173l14.116-19.409c3.898-5.36 2.713-12.865-2.647-16.763L280 259.778V116c0-6.627-5.373-12-12-12h-24c-6.627 0-12 5.373-12
												12v168.222l88.179 64.13c5.36 3.897 12.865 2.712 16.763-2.647z"
										></path>
									</svg>
								</button>
							</div>
						</div>
					</div>

					<!-- - Clear Button - -->
					<div class="px-1">
						<div class="w-full overflow-hidden flex mt-2 lg:mt-0">
							<button
								class=""
								:class="buttonClasses"
								v-on:click="clear()"
							>
								Clear
							</button>
						</div>
					</div>

					<!-- - Export Button -->
					<div class="px-1">
						<div class="w-full flex mt-2 lg:mt-0">
							<DropDown>
								<template v-slot:dropdown-menu-text>
									Export
								</template>
								<template v-slot:dropdown-menu-options>
									<ul>
										<li
											class="py-1 cursor-pointer"
											role="none"
										>
											<button
												class="w-full text-gray-700 group flex items-center px-4 py-2 text-sm"
												role="menuitem"
												tabindex="-1"
												target="_self"
												@click="downloadSMSCSV"
											>
												SMS Report as CSV
											</button>
										</li>
										<li
											class="py-1 cursor-pointer"
											role="none"
										>
											<button
												class="w-full text-gray-700 group flex items-center px-4 py-2 text-sm"
												role="menuitem"
												tabindex="-1"
												target="_self"
												@click="downloadRefundCSV"
											>
												Refund Report as CSV
											</button>
										</li>
									</ul>
								</template>
							</DropDown>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- start of content screen -->

		<!-- If no surfers are found message -->
		<section
			class="min-h-screen w-full max-w-screen-xl mx-auto lg:px-3 my-10"
			v-if="this.activeSurfersWaivers.length < 1"
		>
			<div
				class="w-full py-2 lg:w-5/6 flex flex-wrap items-center justify-center mx-auto"
			>
				<div class="bg-gray-100 py-2 px-4 rounded-full shadow-xl">
					<span class="text-gray-600 text-md font-semibold">
						Your Search Did Not Match Any Surfers
					</span>
				</div>
			</div>
		</section>

		<!-- - Loop over timeGroupings - -->
		<section
			v-if="this.surfersWaiverTimeGroupings.length > 0"
			class="min-h-screen w-full max-screen-xl mx-auto px-4"
		>
			<div
				v-for="(timeGrouping, index) in surfersWaiverTimeGroupings"
				v-bind:key="`timeGroupingList-${index}`"
				v-bind:id="`timeGroupingList-${timeGrouping.performanceDatetime}`"
				:class="timeGroupingVisible(timeGrouping) ? '' : 'hidden'"
			>
				<div class="px-4 sm:px-8 overflow-x-auto py-4">
					<div
						class="inline-block min-w-full lg:rounded-xl border border-gray-600 overflow-hidden"
					>
						<table class="min-w-full leading-normal">
							<thead>
								<tr>
									<th
										class="px-5 py-3 border-b-2 border-gray-200 bg-gray-600 text-left text-xs font-semibold text-gray-100 uppercase tracking-wider"
									>
										Surfer
									</th>
								</tr>
							</thead>
							<tbody>
								<!-- - Loop over surfers - -->
								<tr
									v-for="(surfer, index) in timeGrouping.waivers.filter(
										surfer =>
											surfer.surfName !=
												'Spectator Admission' &&
											surfer.surfName !=
												'Under 6 Spectator Admission'
									)"
									v-bind:key="index"
									v-bind:id="surfer.ticketId"
									:class="surferVisible(surfer) ? '' : 'hidden'"
								>
									<td
										class="p-5 border-b border-gray-200 bg-white w-5/12 lg:w-3/12"
									>
										<div class="flex">
											<div>
												<p
													class="text-gray-900 text-xs lg:text-sm whitespace-nowrap"
												>
													<span
														class="uppercase font-semibold tracking-wider"
													>
														{{ surfer.lastName }}
													</span>
													<span class="capitalize">
														{{
															surfer.firstName.length
																? `, ${surfer.firstName}`
																: "No Name"
														}}
													</span>
												</p>
												<p class="text-gray-600 text-xs lg:text-sm tracking-wider whitespace-normal">
													{{ surfer.accountAk }}
												</p>
												<p class="text-gray-900 text-xs whitespace-nowrap">
													{{ surfer.surfName }}
												</p>
												<div class="flex">
													<p
														class="flex mt-2 text-xs bg-gray-700 py-1 px-2 rounded-lg text-white min-w-min"
													>
														{{ $moment(surfer.performanceDatetime).format("h:mm a") }}
													</p>
												</div>
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	/* eslint max-len: ["error", { "ignoreUrls": true }] */
	/* eslint max-len: ["error", { "ignoreComments": true }] */
	/* eslint max-len: ["error", { "ignoreStrings": true }] */
	/* eslint max-len: ["error", { "ignoreTemplateLiterals": true }] */
	/* eslint max-len: ["error", { "code": 300 }] */
	/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */
	/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["bar"] }] */
	import { mapState, mapActions } from "vuex";
	import Vue from "vue";

	//component imports
	import Nav from "@/components/Nav.vue";
	import WaveLoader from "@/components/WaveLoader.vue";
	import DropDown from "@/components/DropDown.vue";
	import SurfAkDropDown from "@/components/SurfAkDropDown.vue";

	// outside-vue
	const { Parser } = require("json2csv");
	const todayMidnight = new Date()
	todayMidnight.setHours(0, 0, 0, 0);

	// inside-vue
	export default {
		// #region Setup

		//initialize components
		components: {
			Nav,
			WaveLoader,
			DropDown,
			SurfAkDropDown
		},

		//data
		data: () => ({
			navText: "Cancellations",
			buttonClasses:
				"w-full bg-pear-500 hover:bg-pear-700 text-sherpa-500 text-center text-sm font-bold py-3 px-4 rounded-full transition duration-100",

			dateSearch: (todayMidnight).toISOString().substring(0, 10),
			dateSearched: Date.parse(todayMidnight),

			refundReport: [],

			surfAkDropDownOptionsSelected: [],
			surfAkDropDownButtonText: "Please select a surf AK...",
			surfAkDropDownIsOpen: false
		}),

		// #endregion Setup
		// #region Data Filtering

		// computed properties
		computed: {
			// Mapped State From Vuex Namespaced Module "surfers" Store:
			...mapState("surfers", [
				"activeSurfersWaivers",
				"fetchingSurfersWaiversLoading",
				"fetchingSurfersWaiversSuccess",
				"fetchingSurfersWaiversTimestamp"
			]),

			surfersWaiverTimeGroupings() {
				const timeGroupings = {};

				// Loop over our activeSurfersWaivers	
				this.activeSurfersWaivers.forEach(surfersWaiver => {
					// If we don't have a grouping ready to populate let's make one
					if (!timeGroupings[surfersWaiver.performanceDatetime]) {
						timeGroupings[surfersWaiver.performanceDatetime] = {
							performanceDatetime: surfersWaiver.performanceDatetime,
							waivers: []
						};
					}

					// Add the surfersWaiver to the corresponding time grouping
					timeGroupings[surfersWaiver.performanceDatetime].waivers.push(
						surfersWaiver
					);
				});

				// Return as an array instead of an object
				return Object.values(timeGroupings);
			},

			surfAkDropDownEvents() {
				const eventGroupings = {};

				// Loop over our activeSurfersWaivers	
				this.activeSurfersWaivers.forEach(surfersWaiver => {
					// If we don't have a grouping ready to populate let's make one
					if (!eventGroupings[surfersWaiver.eventAk]) {
						eventGroupings[surfersWaiver.eventAk] = {
							eventAk: surfersWaiver.eventAk,
							eventName: (
								surfersWaiver.surfName
									.replace("- Left", "")
									.replace("- Right", "")
							)
						};
					}

					// We've got nothing we need to add by default; only on unique instances here
				});

				// Return as an array instead of an object
				return Object.values(eventGroupings);
			},

			surfAkDropDownOptions() {
				// Create an object to group performanceDatetime values with associated eventAk values
				const performanceGroupings = {};

				// Loop over our time groupings
				this.surfersWaiverTimeGroupings.forEach(timeGrouping => {
					// If we don't have a grouping ready to populate let's make one
					if (!performanceGroupings[timeGrouping.performanceDatetime]) {
						performanceGroupings[timeGrouping.performanceDatetime] = {
							performanceDatetime: timeGrouping.performanceDatetime,
							eventAks: []
						};
					}

					// Add the eventAk(s) to the corresponding performance grouping
					timeGrouping.waivers.forEach(waiver => {
						// Avoid adding the same eventAk to the array multiple times
						if (
							!performanceGroupings[timeGrouping.performanceDatetime].eventAks.includes(
								waiver.eventAk
							)
						) {
							// Store eventAk in an array
							performanceGroupings[timeGrouping.performanceDatetime].eventAks.push(
								waiver.eventAk
							);
						}
					});
				});

				// Return as an array instead of an object
				return Object.values(performanceGroupings);
			}
		},

		// #endregion Data Filtering
		// #region Lifecycle Hooks

		// 
		created() {
			// Dispatch Action ['surfSites/fetchSurfSiteLocations'] to Vuex:
			this.fetchSurfSiteLocationsAction();

			// Utilize Anchor
			if (window.location.hash) {
				this.dateSearch = window.location.hash.replace("#", "");
			}

			// Before base search
			this.dateSearchAction();
		},

		// #endregion Lifecycle Hooks

		//component methods
		methods: {
			// #region Data Fetching

			// Mapped Store Actions
			...mapActions([
				"surfers/setSurfersWaivers",
				"surfers/fetchSurfersWaivers",
				"surfers/updateSurfersWaiver"
			]),
			...mapActions(["locations/fetchSurfSiteLocations"]),

			// Wave Waivers List Specific Functionality:
			async fetchSurfSiteLocationsAction() {
				await this["locations/fetchSurfSiteLocations"]();
			},
			async fetchActiveSurfersWaiversAction() {
				// This'll update the state value of "activeSurferWaivers"
				await this["surfers/fetchSurfersWaivers"]({
					date: new Date(this.dateSearched).toISOString()
				});
			},

			// #endregion Data Fetching
			// #region CSV Methods

			async downloadRefundCSV() {
				await this.fetchRefunds();
				const refundReportCSVDownload = this.getRefundCSV();
				window.open(refundReportCSVDownload); //`Cancellations_SMS_NEW_${this.dateSearched}.csv`
			},
			async fetchRefunds() {
				// Setup
				const params = {
					date: new Date(this.dateSearched).toISOString()
				};
				const payload = {};

				// Apply filters
				if (this.surfAkDropDownOptionsSelected.length) {
					// Prepare for data
					params.performance_time = {};
					payload.event_aks = {};

					// Find and enter the data
					this.surfAkDropDownOptionsSelected.forEach((optionSelected) => {
						const performanceDatetimeFormatted = this.$moment(optionSelected.performanceDatetime).format("HH:mm:ss");
						if(!params.performance_time[performanceDatetimeFormatted])
							params.performance_time[performanceDatetimeFormatted] = true;
						if(!payload.event_aks[optionSelected.eventAk])
							payload.event_aks[optionSelected.eventAk] = true;
					});

					// Format as arrays
					params.performance_time = Object.keys(params.performance_time);
					payload.event_aks = Object.keys(payload.event_aks);
				}

				// Request time
				const response = await Vue.prototype.$surfCancellations.fetchSurfRefunds(
					params,
					payload
				);
				if (!response.success) return;

				//turning the data we get back from the API into something we can use to do .map
				const refundReportArray = Object.keys(response.data).map(
					key => {
						return { ...response.data[key] };
					}
				);

				const refundArrayReduced = refundReportArray.map(refund => ({
					reservationAk: refund.reservationAk,
					reservationOwnerAk: refund.reservationOwnerAk,
					reservationOwnerEmailAddress: refund.reservationOwnerEmailAddress,
					ticketPriceTotal: refund.ticketPriceTotal,
					ticketQuantityTotal: refund.ticketQuantityTotal
				}));
				this.refundReport = refundArrayReduced;
			},
			getRefundCSV() {
				const refundReportCSV = new Parser().parse(this.refundReport);
				const blob = new Blob([refundReportCSV], {
					type: "text/csv;charset=utf-8;"
				});
				return URL.createObjectURL(blob);
			},
			downloadSMSCSV() {
				// Filter to only show visible surfer waivers
				const surfWaiversFiltered = this.activeSurfersWaivers.filter((surfer) => this.surferVisible(surfer));

				// Array Map our SurfWaivers so that each object now contains a reduced subset of keys
				const surfWaiversReduced = surfWaiversFiltered.map(
					surfWaiver => ({
						accountAk: surfWaiver.accountAk,
						reservationAk: surfWaiver.reservationAk,
						ticketId: surfWaiver.ticketId,
						ticketOwnerEmailAddress: surfWaiver.ticketOwnerEmailAddress,
						performanceDatetime: surfWaiver.performanceDatetime,
						performanceAk: surfWaiver.performanceAk,
						emailAddress: surfWaiver.emailAddress,
						firstName: surfWaiver.firstName,
						lastName: surfWaiver.lastName,
						mobileNumber: surfWaiver.mobileNumber,
						surfAk: surfWaiver.surfAk,
						surfCode: surfWaiver.surfCode,
						surfName: surfWaiver.surfName
					})
				);

				// Create CSV string from our CSV JSON
				const convertedCsv = new Parser().parse(surfWaiversReduced);

				// Create into a blob / temporary file then assign that URL to a download button
				const blob = new Blob([convertedCsv], {
					type: "text/csv;charset=utf-8;"
				});

				// Download the CSV
				window.open(URL.createObjectURL(blob));
			},

			// #endregion CSV Methods
			// #region Visibility Statues

			timeGroupingVisible(timeGrouping) {
				return !this.surfAkDropDownOptionsSelected.length || 
					(this.surfAkDropDownOptionsSelected.findIndex(
						(optionSelected) => (
							optionSelected.performanceDatetime == timeGrouping.performanceDatetime
						)
					) != -1);
			},
			surferVisible(surfer) {
				return !this.surfAkDropDownOptionsSelected.length || 
					(this.surfAkDropDownOptionsSelected.findIndex(
						(optionSelected) => (
							optionSelected.performanceDatetime == surfer.performanceDatetime &&
							optionSelected.eventAk == surfer.eventAk
						)
					) != -1);
			},

			// #endregion Visibility Statues
			// #region Date Searching

			/*
				Method to clear the selected date + time when the clear button is pressed
			*/
			clear() {
				this.dateSearch = (todayMidnight).toISOString().substring(0, 10);
				this.dateSearchAction();
			},

			dateSearchAction() {
				// Catch empty searches
				if(!this.dateSearch)
					return;

				const dateParsed = Date.parse(this.dateSearch);
				if(!isNaN(dateParsed)) {
					// Reset Dropdown
					this.surfAkDropDownOptionsSelected = [];

					// Reset States
					this.dateSearched = dateParsed;
					this.dateSearch = (new Date(dateParsed)).toISOString().substring(0, 10);
					window.location.hash = (new Date(dateParsed)).toISOString().substring(0, 10);

					// Dispatch Action ['surfers/fetchSurfersWaivers'] to Vuex:
					this.fetchActiveSurfersWaiversAction();
				}
			}

			// #endregion Date Searching
		}
	};
</script>
