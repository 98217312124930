<template>
	<div>
		<header class="w-full sticky top-0 z-10">
			<div class="w-full bg-gray-700">
				<div class="container mx-auto py-2 lg:p-8 lg:py-3">
					<div class="w-full flex justify-between items-center p-2 text-white">
						<div class="w-1/4 text-white p-5">
							<WaveLogo class="cursor-pointer h-16 md:h-12 lg:h-16" />
						</div>
						<div class="hidden md:block">
							<h1 class="text-2xl">{{ NavText }}</h1>
						</div>
						<div class="w-1/4 text-right">
							<p class="m-0">{{ new Date() | moment("dddd Do MMMM") }}</p>
						</div>
					</div>
				</div>
			</div>
		</header>
	</div>
</template>

<script>
	import WaveLogo from "@/components/WaveLogo.vue";

	export default {
		components: {
			WaveLogo
		},
		props: {
			NavText: {
				type: String,
				default: "This is text"
			}
		}
	}

</script>